import { useContext, useEffect, useState, useReducer } from 'react';
import { toast } from 'react-toastify';

import { SearchDetailContext, SearchDetailContextProps } from '../../../context/searchDetail/searchDetailContext';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';
import { useAuthState } from '../../../providers/authProvider';
import { gridReducer, GRID_ACTIONS, IGridReducerState } from '../../../reducer/gridReducer/gridReducer';
import { getInitialType } from '../../../utils/getInitialTypes';
import { downloadCSV } from '../../../helpers/downloadCSV';

import convertArrayToCSV from 'convert-array-to-csv';
import { getSelectedCustomerId } from '../../../helpers/customer';
import { formatDate, formatDateReverse } from '../../utils/date/date';
import { getPaymentHistory, getPaymentHistoryDownload } from '../../../common/api/paymentApi';
import { IPaymentHistory } from '../../../types/payment';
import PaymentHistoryComponent from './PaymentHistory';

const PaymentHistory = () => {
  const authContext = useAuthState();

  const userContext = useContext<UserDetailContextProps>(UserDetailContext);
  const searchContext = useContext<SearchDetailContextProps>(SearchDetailContext);
  const [meterType, setMeterType] = useState<Api.MeterType>(undefined);
  const [isEligibleForSmartMeter, setIsEligibleForSmartMeter] = useState<boolean>(false);

  const [initialType] = useState<string>(
    getInitialType(
      userContext.userDetail.hasElectricity,
      userContext.userDetail.hasGas,
      userContext.userDetail.hasRenewable
    )
  );

  const initialState: IGridReducerState = {
    page: 1,
    currentType: getInitialType(
      userContext.userDetail.hasElectricity,
      userContext.userDetail.hasGas,
      userContext.userDetail.hasRenewable
    ),
    data: {},
    initialSortField: 'transaction-date',
    initialSortOrder: 'DESC',
    currentSortField: 'transaction-date',
    currentSortOrder: 'DESC',
    isLoading: false,
    hasMoreData: true,
    selectedCustomerId: getSelectedCustomerId(userContext.userDetail),
  };

  const [state, dispatch] = useReducer(gridReducer, initialState);

  // When navigating directly to url
  useEffect(() => {
    if (userContext.userDetail.essCustomerId) {
      initialState.currentType = getInitialType(
        userContext.userDetail.hasElectricity,
        userContext.userDetail.hasGas,
        userContext.userDetail.hasRenewable
      );
      initialState.selectedCustomerId = getSelectedCustomerId(userContext.userDetail);

      dispatch({ type: GRID_ACTIONS.REINIT, payload: initialState });
    }
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.userDetail.hasElectricity, userContext.userDetail.hasGas, userContext.userDetail.hasRenewable]);

  useEffect(() => {
    // Ignore if we are entering component from Search
    if (
      searchContext.searchDetail != null &&
      searchContext.searchDetail.type &&
      searchContext.searchDetail.type.length > 0
    )
      return;

    // Don't load if no customer selected
    if (!state.selectedCustomerId) return;

    loadData(state.selectedCustomerId);
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.currentType,
    state.page,
    state.selectedCustomerId,
    state.currentFilter,
    state.currentSortField,
    state.currentSortOrder,
  ]);

  const getPaymentHistoryDetails = async (customerId: string) => {
    let promise = null;
    const paymentHistory: Common.IResult<Array<IPaymentHistory>> = await getPaymentHistory(
      process.env.REACT_APP_API_URL,
      authContext,
      customerId
    );

    promise = new Promise((resolve, reject) => {
      const gridData: Array<any> = paymentHistory.data.map((item: IPaymentHistory) => {
        const paymentHistoryData = {
          'transaction-date': formatDate(item['transaction-date']),
          'transaction-payment-method': item['transaction-payment-method'],
          'user-reference': item['user-reference'],
          'transaction-reference': item['transaction-reference'],
          'total': item['total'].toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        };

        return paymentHistoryData;
      });

      resolve(gridData);
    });

    if (promise instanceof Promise) {
      promise
        .then(result => {
          if (result) {
            dispatch({
              type: GRID_ACTIONS.ADD_DATA,
              payload: { data: result, page: state.page },
            });
          } else {
            console.error(`Payment history request returned errors.`);
          }
        })
        .finally(() => {
          dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });
        });
    }
  };

  const clearData = () => {
    dispatch({ type: GRID_ACTIONS.CLEAR_DATA });
  };

  const loadData = (customerId: string) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: true });

    getPaymentHistoryDetails(customerId);
  };

  const handleTypeChange = (type: string, sort: Common.ISort) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: type });
  };

  const handleSortChange = (type: string, sort: Common.ISort) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_SORT, payload: sort });
  };

  const handleSiteBlur = async (id: number, site: string) => {};

  const handlePagingChange = () => {
    dispatch({ type: GRID_ACTIONS.INCREMENT_PAGE });
  };

  const handleSiteFilterChange = (option: Common.IOption) => {
    const essSiteId = option.value;
    dispatch({ type: GRID_ACTIONS.FILTER_BY_SITE, payload: { essSiteId: essSiteId } });
  };

  const handleCustomerFilterChange = (option: Common.IOption) => {
    dispatch({ type: GRID_ACTIONS.FILTER_BY_CUSTOMER, payload: { essCustomerId: option.value } });
    setIsEligibleForSmartMeter(false);
  };

  const handleAccountFilterChange = (option: Common.IOption) => {
    const essAccountId = option.value;
    dispatch({ type: GRID_ACTIONS.FILTER_BY_ACCOUNT, payload: { essAccountId } });
  };

  const handleMpanMprnFilterChange = (option: Common.IOption) => {
    const mprnMpan = option.value;
    dispatch({ type: GRID_ACTIONS.FILTER_BY_MPANMPRN, payload: { mprnMpan } });
  };

  const handleDateFilterChange = (formattedMonthYear: string) => {
    const date = formattedMonthYear;
    dispatch({ type: GRID_ACTIONS.FILTER_BY_DATE, payload: { date } });
  };

  const handleClearAllClick = (): void => {
    dispatch({ type: GRID_ACTIONS.CLEAR_FILTERS });
  };

  const handleDownload = async (customerAccountReference: string) => {
    const date = new Date();
    const currentDate = formatDateReverse(date);

    const paymentDownload = await getPaymentHistoryDownload(
      process.env.REACT_APP_API_URL,
      authContext,
      customerAccountReference
    );

    if (paymentDownload.success && paymentDownload.data) {
      const paymentDownloadData: Array<any> = paymentDownload.data.map((item: any) => {
        return {
          'transaction-date': item['transaction-date'],
          'transaction-payment-method': item['transaction-payment-method'],
          'customer-account-reference': item['customer-account-reference'],
          'mpan-core': item['mpan-core'],
          'collection-reference':
            item['transaction-payment-method'] === 'Non-Direct Debit' ? '' : item['user-reference'],
          'site-name': item['site-name'],
          'site-post-code': item['site-post-code'],
          'total': item.total,
        };
      });

      if (paymentDownloadData == null) return;
      let result = null;
      const csv = convertArrayToCSV(paymentDownloadData);
      result = csv.toString();

      if (result !== null) {
        const fileName = `${customerAccountReference}-Payment-History-${currentDate}`;

        // Download file
        downloadCSV(result, fileName);
      }
    } else {
      const message = 'Unable to get payment history';
      toast.error(message, { position: 'bottom-right' });
      throw new Error(message);
    }
  };

  const handleMeterTypeFilterChange = (type: Api.MeterType) => {
    dispatch({ type: GRID_ACTIONS.FILTER_BY_METER_TYPE, payload: type });
    return type === 'all' ? setMeterType(undefined) : setMeterType(type);
  };

  return (
    <PaymentHistoryComponent
      initialType={initialType}
      handleTypeChange={handleTypeChange}
      handleSortChange={handleSortChange}
      handleSiteBlur={handleSiteBlur}
      handlePagingChange={handlePagingChange}
      gridData={state.gridData}
      hasRenewableAccount={userContext.userDetail.hasRenewable}
      hasElectricityAccount={userContext.userDetail.hasElectricity}
      hasGasAccount={userContext.userDetail.hasGas}
      customerData={state.data?.customers}
      accountData={state.data?.accounts}
      siteData={state.data?.sites}
      mpanMprnData={state.data?.mpans}
      handleAccountFilterChange={handleAccountFilterChange}
      handleCustomerFilterChange={handleCustomerFilterChange}
      handleSiteFilterChange={handleSiteFilterChange}
      handleMpanMprnFilterChange={handleMpanMprnFilterChange}
      handleDateFilterChange={handleDateFilterChange}
      handleMeterTypeFilterChange={handleMeterTypeFilterChange}
      handleDownload={handleDownload}
      handleClearAllClick={handleClearAllClick}
      isLoading={state.isLoading}
      hasMoreData={state.hasMoreData}
      currentSortField={state.currentSortField}
      currentSortOrder={state.currentSortOrder}
      eligibleForSmartMeter={isEligibleForSmartMeter}
    />
  );
};
export default PaymentHistory;
