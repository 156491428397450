import { useContext, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import { smeNavigation } from './smeNavigation';
import { getMediaSize } from '../../utils/media/media';
import { trimAndLowerCase } from '../../utils/text/text';
import { smeBrokerNavigation } from './smeBrokerNavigation';
import { corporateNavigation } from './corporateNavigation';
import { useAuthState } from '../../../providers/authProvider';
import { corporateBrokerNavigation } from './corporateBrokerNavigation';
import { exclusiveoffers } from '../../../common/api/exclusiveOffersApi';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';

import { BRANDS } from '../../../consts';
import { Icon } from '../../../common/components';
import * as colors from '../../../common/styles/colors';
import { iconsToUse } from '../../../common/styles/themes';
import { TagManager } from '../../utils/analytics/TagManager';
import * as variables from '../../../common/styles/variables';
import { GlobalContext, GlobalDetailContextProps } from '../../../context/globalContext/globalContext';
import { getMeters } from '../../../common/api/meterReadsApi';
import { internalNavigation } from './internalNavigation';
import { temporary } from '../../../helpers/axiosWrapper';
import { isTpiUser } from '../../../helpers/tpiUser';

declare var process: {
  env: {
    REACT_APP_ADMIN_SITE: string;
    REACT_APP_BRAND: BRANDS;
    REACT_APP_API_URL: string;
  };
};

type NavigationTemplateProps = {
  handleShowSubMenu: Function;
  onClick: Function;
  hasUnreadMessages: boolean;
  handleOpenMenu: Function;
  handleCloseMenu: Function;
};

const defaultProps = {
  handleShowSubMenu: undefined,
  onClick: undefined,
  hasUnreadMessages: false,
  handleOpenMenu: undefined,
  handleCloseMenu: undefined,
};

export const NavigationTemplate = (props: NavigationTemplateProps) => {
  const globalContext = useContext<GlobalDetailContextProps>(GlobalContext);
  const { userDetail, isSMECustomer, isSMEBroker, isCorpCustomer, isCorpBroker } =
    useContext<UserDetailContextProps>(UserDetailContext);
  const [customerData, setCustomerData] = useState<Array<Navigation.INavigationData>>([]);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [exclusiveOffersData, setExclusiveOffersData] = useState<Array<Api.IExclusiveOffersData>>([]);
  const [showMeterReadButtons, setShowMeterReadButtons] = useState<boolean>(false);
  const [showDownloadHHDataPage, setShowDownloadHHDataPage] = useState<boolean>(false);
  const authContext = useAuthState();
  const isDesktop = getMediaSize() === 'isDesktop';

  const getExclusiveOffers = async () => {
    let userType: string = 'Corporate';

    const accessId = temporary.accessId;
    if (accessId && accessId.length > 0) {
      userType = 'TPI';
    }

    const exclusiveOffers: Common.IResult<Array<Api.IExclusiveOffersData>> = await exclusiveoffers(
      process.env.REACT_APP_API_URL,
      authContext,
      userType
    );
    if (exclusiveOffers && exclusiveOffers.success) {
      setExclusiveOffersData(exclusiveOffers.data);

      const options: Array<Common.IOption> = [];
      exclusiveOffers.data.forEach((item: any) => {
        const index = options.findIndex(i => i.value === item.offerType);
        if (index === -1) {
          options.push({ value: item.offerType, display: item.offerType });
        }
      });

      globalContext.setGlobalDetail({
        ...globalContext.globalDetail,
        exclusiveOffersOptions: options,
      });
    } else {
      const message = 'API error calling: exclusiveoffers';
      console.error(message);
      toast.error(message, { position: 'bottom-right' });
    }
  };

  const updateMenuForNHH = async () => {
    // If multiple customer accounts - Show Meter read buttons
    if (userDetail.customerAccounts.length > 1) {
      setShowMeterReadButtons(true);
      return;
    }

    // Check Single customer account has NHH
    const essCustomerId = userDetail.essCustomerId;

    const mpanDetailsData: Array<Api.IMpanDetail> = await getMeters(
      process.env.REACT_APP_API_URL,
      authContext,
      essCustomerId,
      undefined,
      undefined,
      undefined,
      'NHH',
      'Active'
    );

    if (mpanDetailsData && mpanDetailsData.length > 0) {
      setShowMeterReadButtons(true);
    } else {
      setShowMeterReadButtons(false);
    }
  };

  const updateMenuForHH = async () => {
    // If multiple customer accounts - Show HH Download Data page
    if (userDetail.customerAccounts.length > 1) {
      setShowDownloadHHDataPage(true);
      return;
    }

    // Check Single customer account has HH
    const essCustomerId = userDetail.essCustomerId;

    const mpanHHDetailsData: Array<Api.IMpanDetail> = await getMeters(
      process.env.REACT_APP_API_URL,
      authContext,
      essCustomerId,
      undefined,
      undefined,
      undefined,
      'HH'
    );

    if (mpanHHDetailsData && mpanHHDetailsData.length > 0) {
      setShowDownloadHHDataPage(true);
    } else {
      setShowDownloadHHDataPage(false);
    }
  };

  const updateMenu = () => {
    let showButtons = showMeterReadButtons;
    let showHHPage = showDownloadHHDataPage;
    // If multiple customer accounts - Show Meter read buttons
    if (userDetail.customerAccounts.length > 1) {
      showButtons = true;
      setShowMeterReadButtons(true);
      setShowDownloadHHDataPage(true);
    }

    if (userDetail.customersMeteringPointTypes?.hhMeteringPointTypeOnly) {
      showButtons = false;
    }

    if (userDetail.customersMeteringPointTypes?.nhhMeteringPointTypeOnly) {
      showHHPage = false;
    }

    const navigationProps = {
      brand: process.env.REACT_APP_BRAND,
      isExclusiveOffersDataAvailable: !!exclusiveOffersData.length,
      showMeterReadButtons: showButtons,
      isTpi: isTpiUser(),
      showDownloadHHDataPage: showHHPage,
    };

    if (process.env.REACT_APP_ADMIN_SITE === 'true') {
      setCustomerData(internalNavigation(navigationProps));
    } else if (isCorpBroker()) {
      setCustomerData(corporateBrokerNavigation(navigationProps));
    } else if (isCorpCustomer()) {
      setCustomerData(corporateNavigation(navigationProps));
    } else if (isSMEBroker()) {
      setCustomerData(smeBrokerNavigation(navigationProps));
    } else if (isSMECustomer()) {
      setCustomerData(smeNavigation(navigationProps));
    } else {
      setCustomerData([]);
    }
  };

  const resetGlobalDetail = () => {
    globalContext.setGlobalDetail({
      ...globalContext.globalDetail,
      filterActive: false,
      filteredItems: [],
      customerData: [],
      accountData: [],
      siteData: [],
      mpanMprnData: [],
      selectedCustomer: {},
      selectedAccount: {},
      selectedSite: {},
      selectedType: '',
      selectedMpanMprn: {},
      selectedDate: '',
    });
  };

  const navigationData: Array<Navigation.INavigationData> = customerData;
  const openClass = 'open';

  const handleShowSubMenu = (index: number, event: any) => {
    const wrapper = event.target;
    setActiveIndex(index);
    if (wrapper !== null) {
      wrapper.closest('a').classList.toggle(openClass);
    }
  };

  const handleResetSubMenu = () => {
    setActiveIndex(null);
  };

  const toggleSubMenu = (index: number, event: React.MouseEvent) => {
    event.preventDefault();
    if (index === activeIndex) {
      handleResetSubMenu();
    } else {
      handleShowSubMenu(index, event);
    }
  };

  const handleMobileNavigation = () => {
    props.handleCloseMenu();
  };

  const onMenuLinkClicked = (link: string, linkText: string, linkIcon?: string) => {
    TagManager.pushData({
      event: 'Link - Side Menu',
      link,
      linkText,
      linkIcon,
    });
  };

  useEffect(() => {
    // log for 35984
    console.log('Navigation Template, customerType:' + userDetail.customerType);

    if (userDetail.customerAccounts.length === 0) return;
    if (userDetail.customerType !== undefined && userDetail.customerType.length === 0) return;

    const fetchData = async () => {
      await updateMenuForNHH();
      await updateMenuForHH();
      updateMenu();
      getExclusiveOffers();
    };

    fetchData().catch(console.error);

    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail.customerType, userDetail.essCustomerId, userDetail.customerAccounts]);

  useEffect(() => {
    updateMenu();
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exclusiveOffersData, showMeterReadButtons]);

  const navigationItems = navigationData.map((item: any, index: number) => {
    const subMenu = item.subMenu || [];
    const subMenuItems = typeof subMenu !== 'undefined' && subMenu.length !== 0;
    if (item.hasOwnProperty('isVisible') && !item.isVisible) {
      return <div key={index}></div>;
    } else if (subMenuItems) {
      return (
        <li key={index} className={`sub-menu ${trimAndLowerCase(item.text)}`}>
          <Link
            to='/'
            onClick={event => toggleSubMenu(index, event)}
            className={`${activeIndex === index ? openClass : ''}`}
          >
            <span className='menu-icon'>
              <Icon
                color={colors.oxfordBlue}
                size={variables.iconSizeBase}
                icon={(iconsToUse as any)[`${item.icon}`]}
              />
            </span>
            <span className='menu-item'>{item.text}</span>
            <span className='sub-menu-icon'>
              <Icon
                size={15}
                className='chevron-down'
                color={colors.oxfordBlue}
                icon={activeIndex === index ? 'chevron-up' : 'chevron-down'}
              />
            </span>
          </Link>
          <ul>
            {item.subMenu
              .filter((subItem: Navigation.INavigationDataSubMenu) => subItem.isVisible)
              .map((subItem: Navigation.INavigationDataSubMenu, index: number) => {
                const link = '/home' + subItem.link;

                return (
                  <li key={index} className={`menu ${trimAndLowerCase(subItem.text)}`}>
                    <NavLink
                      to={link}
                      onClick={() => {
                        resetGlobalDetail();
                        onMenuLinkClicked(link, subItem.text);
                        if (!isDesktop) {
                          handleResetSubMenu();
                          handleMobileNavigation();
                        }
                      }}
                    >
                      {subItem.text}
                    </NavLink>
                  </li>
                );
              })}
          </ul>
        </li>
      );
    } else {
      const link = '/home' + item.link;

      return (
        <li key={index} className={`menu ${trimAndLowerCase(item.text)}`}>
          {item.isExternalLink && (
            <a href={item.link} target='_blank' onClick={() => onMenuLinkClicked(item.link, item.text, item.icon)}>
              <span className={`menu-icon ${item.classname}`}>
                <Icon
                  color={colors.oxfordBlue}
                  size={variables.iconSizeBase}
                  icon={(iconsToUse as any)[`${item.icon}`]}
                />
              </span>
              <span className='menu-item'>{item.text}</span>
            </a>
          )}

          {!item.isExternalLink && (
            <NavLink
              to={link}
              onClick={() => {
                handleResetSubMenu();
                resetGlobalDetail();
                onMenuLinkClicked(link, item.text, item.icon);
                if (!isDesktop) {
                  handleMobileNavigation();
                }
              }}
            >
              <span className={`menu-icon ${item.classname}`}>
                <Icon
                  color={colors.oxfordBlue}
                  size={variables.iconSizeBase}
                  icon={(iconsToUse as any)[`${item.icon}`]}
                />
              </span>
              <span className='menu-item'>{item.text}</span>
              {item.link === '/messages' && props.hasUnreadMessages && <span className='message-count' />}
            </NavLink>
          )}
        </li>
      );
    }
  });

  return <>{navigationItems}</>;
};

NavigationTemplate.defaultProps = defaultProps;
