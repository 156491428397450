import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ButtonPrimary, ModalClose } from '../../../common/components';
import { SearchDetailContext, SearchDetailContextProps } from '../../../context/searchDetail/searchDetailContext';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';

import * as colors from '../../../common/styles/colors';
import { sortAccountsByDisplay } from '../../../utils/sortFilters';

type HealthCheckCountModalProps = {
  onHide: () => void;
  show: boolean;
  messagevalues: Messages.IMessagesValues;
  counts: Array<Common.IKeyValue>;
};

const defaultProps = {
  onHide: undefined,
  show: false,
  messagevalues: {},
};

export const HealthCheckCountModalWrapper = styled.div`
  .link {
    &:link {
      text-decoration: none;
    }
    &:visited {
      text-decoration: none;
    }
    &:hover {
      text-decoration: underline;
    }
    color: ${colors.hydroBlue};
  }
  .accountNameNoWrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .countRight {
    text-align: end;
  }
`;

export const HealthCheckCountModal = (props: HealthCheckCountModalProps) => {
  const searchContext = useContext<SearchDetailContextProps>(SearchDetailContext);
  const history = useHistory();

  const { userDetail, setUserDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  let uniqueAccounts: Array<string> = [];

  let customersList: Array<Common.IOption> = [];

  if (props.messagevalues && props.messagevalues.json) {
    uniqueAccounts = Array.from(
      new Set(props.messagevalues.json.map((item: any) => item['customer-account-reference']))
    );

    uniqueAccounts.map((account: string, index: number) => {
      const customer = userDetail.customerAccounts.find(c => c.essAccountID === account);
      const cust: Common.IOption = {
        value: account,
        display: customer ? customer.accountName : '',
      };

      customersList.push(cust);
    });

    customersList = sortAccountsByDisplay(customersList);
  }

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (props.messagevalues.route) {
      history.push(props.messagevalues.route);
    }
  };

  const onAccountClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    const accountId: string = event.currentTarget.innerHTML;

    searchContext.setSearchDetail({
      ...searchContext.searchDetail,
      type: 'account',
      accountId: accountId,
      routingTo: props.messagevalues.routingTo,
      searchText: accountId,
    });
    setUserDetail({
      ...userDetail,
      selectedCustomerAccount: accountId,
      essCustomerId: accountId,
    });

    if (props.messagevalues.route) {
      history.push(props.messagevalues.route);
    }
  };

  return (
    <Modal
      animation={false} // TODO this prevents an error being thrown in the console but it's ugly. https://github.com/react-bootstrap/react-bootstrap/issues/3518
      className={`custom-modal`}
      show={props.show}
      onHide={props.onHide}
      centered={true}
      messagevalues={JSON.stringify(props.messagevalues)}
    >
      <HealthCheckCountModalWrapper>
        <Modal.Header className='pt-0'>
          <Modal.Title>{props.messagevalues.title}</Modal.Title>
          <ModalClose onClick={() => props.onHide()} />
        </Modal.Header>
        <Modal.Body>
          {customersList.map((customer: Common.IOption, index: number) => {
            return (
              <div key={index}>
                <div className='container'>
                  <div className='row'>
                    <div className='col-8 accountNameNoWrap pl-0'>
                      <Link className='link' to={'/'} onClick={onAccountClick}>
                        {customer.value}
                      </Link>
                      <span className='mx-1'>-</span>
                      {customer.display}
                    </div>
                    <div className='col-4 pl-5'>
                      <span>{props.counts.filter(x => x.key === customer.value)[0].value}</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer className='pb-0 pr-0'>
          <ButtonPrimary
            route='/'
            className='mr-0'
            title={props.messagevalues.callToAction}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              event.preventDefault();
              handleClick(event);
              props.onHide();
            }}
          ></ButtonPrimary>
        </Modal.Footer>
      </HealthCheckCountModalWrapper>
    </Modal>
  );
};

HealthCheckCountModal.defaultProps = defaultProps;
