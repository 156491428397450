// Styles
import { Icon } from '../../../../common/components';
import { Insight } from '../Insight/Insight';
import { OurInsightWrapper } from './OurInsight.styles';
import { iconsToUse } from '../../../../common/styles/themes';
import * as variables from '../../../../common/styles/variables';

type OurInsightProps = {};

const defaultProps = {};

export const OurInsight = (props: OurInsightProps) => {
  const accountOverviewJson = [
    {
      title: 'Intelligence',
      description:
        'Expert analysis and commentary from Daniel Starman, our Sales Energy Market Lead, and other energy professionals.',
      imageSrc:
        'https://images.ctfassets.net/jl0e441lxc8n/1SnmjdxdZDgacyJYRyER3V/914778c088f36096c5363b80f560ab88/iStock-1176843801_edited_flipped_low_contrast.jpg?w=700&q=70&fm=webp',
      btnText: 'Understand more',
      btnLink: 'https://energy.drax.com/intelligence/',
    },
    {
      title: 'Blog articles',
      description:
        'Articles that identify and explain the issues you’re likely to be facing – and provide a range of solutions.',
      imageSrc:
        'https://images.ctfassets.net/jl0e441lxc8n/3PoEHLWk1UnttGC3Ej3V7D/a69bec038cf02d5a1fccb7d44f4d7d46/Report_zero_emissions.jpg?w=700&q=70&fm=webp',
      btnText: 'Read our blogs',
      btnLink: 'https://energy.drax.com/blog-articles/',
    },
    {
      title: 'Reports and guides',
      description:
        'More detailed coverage of the problems your organisation may be facing – now and in the future – and what to next.',
      imageSrc:
        'https://images.ctfassets.net/jl0e441lxc8n/5Pn6bQfWpKSLPHXrErVDPN/fe5b280addf11000937aa6cb92f02cae/The_future_of_energy_-_How_8_disruptive_changes_may_affect_your_business.jpg?w=700&q=70&fm=webp',
      btnText: 'Go deeper',
      btnLink: 'https://energy.drax.com/reports-and-guides/',
    },
    {
      title: 'Case studies',
      description:
        'Read how we’ve supported our customers and partners in achieving their commercial and net zero goals.',
      imageSrc:
        'https://images.ctfassets.net/jl0e441lxc8n/33g2HRg6J16lDOVwtIBsQh/01ba0858c078fe02433e35fe57c124cf/AdobeStock_182073037_edited_flipped.jpg?w=700&q=70&fm=webp',
      btnText: 'See success',
      btnLink: 'https://energy.drax.com/case-studies/',
    },
    {
      title: 'News',
      description: 'Get our latest company news covering products, services, community activities and more.',
      imageSrc:
        'https://images.ctfassets.net/jl0e441lxc8n/5llLaJOKmrVhBfY6qhKJwl/c7e1c56415f43bd88f08bd53d37e7e94/Ipswich_office_branding_2021-03__1_.jpg?w=700&q=70&fm=webp',
      btnText: 'Stay updated',
      btnLink: 'https://energy.drax.com/news/',
    },
  ];

  return (
    <OurInsightWrapper>
      <div>
        <h1 className='text-center mb-0 pt-3'>
          <Icon icon={iconsToUse.insights} size={variables.spacingMedium} className='pr-3' />
          Our Insights
        </h1>
        <p className='text-center'>
          For in-depth, expert analysis and commentary about the energy market and more, check out our content:
        </p>

        <div className='row justify-content-center'>
          {accountOverviewJson.map((account: any, i: number) => (
            <div key={i} className='col-xs-12 pb-4'>
              <Insight
                title={account.title}
                description={account.description}
                imageSrc={account.imageSrc}
                btnText={account.btnText}
                btnLink={account.btnLink}
              ></Insight>
            </div>
          ))}
        </div>
      </div>
    </OurInsightWrapper>
  );
};

OurInsight.defaultProps = defaultProps;
