import styled from 'styled-components';

import * as colors from '../../common/styles/colors';
import * as variables from '../../common/styles/variables';
import * as mixins from '../../common/styles/mixins';
import { respondTo } from '../../common/styles/mixins';

export const InvoicesWrapper = styled.div`
  padding-left: ${variables.defaultSpacer};
  padding-right: ${variables.defaultSpacer};

  ${respondTo.tablet`
    padding-left: 0px;
    padding-right: 0px;
  `};

  .primary {
    text-align: center;

    ${respondTo.tablet`
      text-align: left;
    `};
  }

  .no-messages {
    height: 50vh;
  }

  .expand {
    border-top: 1px solid ${colors.darkGrey};

    th {
      color: ${({ theme }) => theme.text.secondary};
      background-color: ${colors.lightGrey} !important;
    }
  }

  .table-fixed {
    ${respondTo.tablet`
      padding-right: 33px;
    `};
    height: calc(100vh - 280px);
  }

  .list {
    ${mixins.textWrap};

    ${respondTo.mobile`
      overflow-wrap: initial;
    `};

    .heading {
      color: ${({ theme }) => theme.text.secondary};
    }

    thead {
      display: table-header-group;
    }

    .statement {
      white-space: nowrap;
      display: table-cell;
    }

    thead,
    tbody {
      .icon {
        padding-left: 0px;
      }
    }

    tr {
      position: relative;
    }

    tbody {
      border-top: none;

      .date {
        padding-left: 0.75rem;
      }

      .actions-wrapper {
        white-space: nowrap;
        opacity: 0;
        vertical-align: middle;

        a {
          pointer-events: none;
          padding-right: 0.7em;

          &:last-child {
            padding-right: 0;
          }
        }
      }

      &.selected {
        tr {
          background-color: ${({ theme }) => theme.bg.secondary};
        }

        .actions-wrapper {
          display: inherit;
          opacity: 1;
          pointer-events: all;

          a {
            pointer-events: all;
          }
        }
      }

      tr {
        ${mixins.transitionFast};
        cursor: pointer;

        &.selected {
          td {
            background-color: ${({ theme }) => theme.bg.secondary};

            input {
              background-color: ${colors.white};
            }
          }

          .actions-wrapper {
            opacity: 1;
            pointer-events: all;

            a {
              pointer-events: all;
            }
          }
        }

        .item {
          cursor: pointer;
        }
      }
    }

    .checkbox {
      label {
        display: inline;
      }

      span {
        bottom: 0;
      }
    }

    .icon-chevron {
      position: relative;
      top: -0.1em;
      left: 0.1em;
    }

    .energyTypeIcon {
      position: relative;
      top: -0.2em;
      left: 0.5em;
    }

    .expandable-content {
      display: flex;
      flex-direction: column;

      td {
        display: flex;

        &:first-child {
          position: inherit;
        }
      }

      span,
      p {
        text-align: left;
        white-space: break-spaces;
        margin-bottom: 0;
      }

      ${respondTo.tablet`
          display: table-row;

          td {
            display: table-cell;
          }
      `};

      & > td {
        &:first-child {
          position: relative !important;
        }
      }
    }

    .amount-cell {
       ${respondTo.tablet`
        width: auto;
       `};
       text-align: end;
    }
  }

  .help-text {
    text-align: center;
    margin: 10px auto;
  }

  .warning-text {
    font-style: italic;
    margin: 10px 0px 0px auto;
    font-size: 0.9rem;
    width: 20%;
    text-align: center;
  }
`;

export const InvoicesGridWrapper = styled.div`
  position: relative;
  min-height: 200px;

  tbody:after {
    background: none !important;
  }
`;
