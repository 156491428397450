import styled from 'styled-components';

import * as colors from '../../styles/colors';
import * as variables from '../../styles/variables';
import * as mixins from '../../styles/mixins';

export const StyledLink = styled(
  ({ textOnly, disabled, enabledStatus, disabledStatus, lockedStatus, borderRadiusType, ...props }) => {
    if (typeof props.href === 'string') {
      // TODO:  Might want to remove this and revisit this problem later
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      return <a {...props} />;
    }
    return <div {...props} />;
  }
)`
  ${mixins.transition};
  font-weight: ${props => props.theme.button.fontWeight};
  color: ${colors.white} !important;
  text-decoration: none;
  padding: 0.2rem 1rem !important;
  max-width: 100px;
  white-space: nowrap;
  margin-top: -5px;
  text-align: center !important;
  border: 2px solid ${props => props.theme.primary.border};
  background: ${props => props.theme.primary.bg};

  // disabledStatus
  ${({ disabledStatus, theme }) =>
    disabledStatus && {
      'border': `2px solid black`,
      'color': `black !important`,
      'background': colors.white,
      '--hovercolor': theme.primary.bg,
    }};
  // lockedStatus
  ${({ lockedStatus, theme }) =>
    lockedStatus && {
      'border': `2px solid red`,
      'color': `red !important`,
      'background': colors.white,
      '--hovercolor': theme.primary.bg,
    }};
  // Text only
  ${({ textOnly }) =>
    textOnly && {
      'border': 'none',
      'background': 'none',
      'color': 'unset!important',
      '--hovercolor': 'transparent',
    }}

  // Border radius
  ${({ borderRadiusType, theme }) => {
    let borderRadius = theme.primary.btnBorderRadius;

    if (borderRadiusType === 'squared') {
      borderRadius = variables.btnBorderRadiusSquare;
    } else if (borderRadiusType === 'none') {
      borderRadius = '0';
    }
    return { 'border-radius': borderRadius };
  }}
`;
