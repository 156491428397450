import React, { useState, useEffect, useRef, useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import IcomoonReact from 'icomoon-react';
import styled from 'styled-components';
import moment from 'moment';

import iconSet from '../../assets/selection.json';
import { BRANDS } from '../../consts';

import { useAuthState } from '../../providers/authProvider';
import { UserDetailContext, UserDetailContextProps } from '../../context/userDetail/userDetailContext';

import {
  ButtonTerinary,
  ExpandableRow,
  ButtonPrimary,
  RowInputItem,
  NoResults,
  Loading,
  Table,
  GridTypeSelector,
} from '../../common/components';

import { StyledTitle, FilterRowWrapper } from '../../common/components';
import { GridFilter } from '../gridfilter/GridFilter';
import { getFuelIcon } from '../../utils/getFuelIcon';
import { EssAccountsTypes } from '../../types/account';
import { respondTo } from '../../common/styles/mixins';
import { globalApiParams } from '../../common/api/globals';
import { breakpoints } from '../../common/styles/variables';
import { isDraxBrand, isOpusBrand } from '../../utils/common';
import { TagManager } from '../utils/analytics/TagManager';

import { InvoicesWrapper, InvoicesGridWrapper } from './Invoices.styles';
import { DATE_FORMAT } from '../../common/constants';
import { downloadInvoices } from '../../common/api/invoicesApi';
import { InvoiceDownloadModal } from '../mymessages/modalTemplates/InvoiceDownloadModal';

type InvoicesComponentProps = {
  hasElectricityAccount: boolean;
  hasGasAccount: boolean;
  hasRenewableAccount: boolean;
  initialType: string;
  gridData: Array<Api.IInvoiceDetail | Api.IRenewableStatement>;
  handleTypeChange: Function;
  handleSortChange: Function;
  handleSiteBlur: Function;
  handlePagingChange: any;
  handleCustomerFilterChange: Function;
  handleAccountFilterChange: Function;
  handleSiteFilterChange: Function;
  handleMpanMprnFilterChange: Function;
  handleDateFilterChange: Function;
  handlePeriodFilterChange: Function;
  handleClearAllClick: Function;
  handleDownloadReport: Function;
  handleDownloadBackingData: Function;
  handleDownloadSummary: Function;
  customerData: Array<Common.IOption>;
  accountData: Array<Common.IOption>;
  siteData: Array<Common.IOption>;
  mpanMprnData: Array<Common.IOption>;
  isLoading?: boolean;
  hasMoreData: boolean;
  currentSortField: string;
  currentSortOrder: 'ASC' | 'DESC' | '';
};

const defaultProps = {
  gridData: [],
  customerData: [],
  accountData: [],
  siteData: [],
  mpanMprnData: [],
  hasElectricityAccount: false,
  hasGasAccount: false,
  hasRenewableAccount: false,
  initialType: EssAccountsTypes.Electricity,
  isLoading: true,
  hasMoreData: true,
  currentSortField: '',
  currentSortOrder: 'ASC',
};

const invoicesHeaders: Array<Common.HeaderItem> = [
  {
    title: '',
    dataValue: 'icon',
  },
  {
    title: 'Invoice',
    dataValue: 'invoiceNumber',
  },
  {
    title: 'Account',
    dataValue: 'accountID',
  },
  {
    title: 'Period',
    dataValue: 'month',
  },
  {
    title: 'Site',
    dataValue: 'siteReference',
  },
  {
    title: 'Amount',
    dataValue: 'amount',
  },
];

const statementHeaders: Array<Common.HeaderItem> = [
  {
    title: '',
    dataValue: 'icon',
  },
  {
    title: 'Statement',
    sortField: 'statementId',
    dataValue: 'invoiceReference',
  },
  {
    title: 'Description',
    sortField: 'description',
    dataValue: 'invoiceName',
  },
  {
    title: 'Period',
    dataValue: 'month',
  },
  {
    title: 'Site',
    sortField: 'site',
    dataValue: 'customerSiteReference',
  },
  {
    title: 'Amount',
    sortField: 'amount',
    dataValue: 'invoiceValue',
  },
];

const draxInvoicesHeaders: Array<Common.HeaderItem> = [
  {
    title: 'Invoice',
    dataValue: 'invoiceNumber',
    sortField: 'transaction-reference',
    minWidth: 110,
    responsive: {
      [breakpoints.mobile]: 140,
    },
  },
  {
    title: 'Site reference',
    dataValue: 'siteReference',
    sortField: 'site-reference',
    className: 'd-none d-md-table-cell',
    minWidth: 140,
  },
  {
    title: 'MPAN',
    dataValue: 'mpanElec',
    sortField: 'mpan-core',
    className: 'd-none d-md-table-cell',
    minWidth: 140,
  },
  {
    title: 'Period',
    dataValue: 'month',
    sortField: 'billing-period-end-date',
    className: 'd-none d-md-table-cell',
    minWidth: 140,
  },
  {
    title: 'Amount (£)',
    dataValue: 'amount',
    sortField: 'total',
    minWidth: 120,
    width: 120,
  },
];

const StyledTd = styled.td<any>`
  p,
  span {
    width: 50%;
  }

  p {
    display: flex;
    align-items: end;
    margin-top: auto;
  }

  ${respondTo.tablet`
    width: ${({ colWidth }: any) => colWidth};
    p, span {
      margin-left: -10px;
      width: auto;
    }

    p {
      display: block;
    }
  `};
`;

const InvoicesComponent = (props: InvoicesComponentProps) => {
  const authContext = useAuthState();
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [selectedType, setSelectedType] = useState<string>(props.initialType);
  const [expandRow, setExpandRow] = useState<boolean>(false);
  const [gridTypes, setGridTypes] = useState<Array<string>>();
  const [isAllSelected, setSelectAll] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<Array<number>>([]);
  const [downloadOptions, setDownloadOptions] = useState<Array<Common.IOption>>([]);
  const [isDownloadRequestedModalOpened, setIsDownloadRequestedModalOpened] = useState(false);
  const scrollableTable = useRef<any>();

  const sortDetails: Common.ISort = { field: props.currentSortField, order: props.currentSortOrder };

  const scrollToTop = () => {
    if (scrollableTable.current) {
      scrollableTable.current.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (props.gridData.length <= globalApiParams.pageSize) {
      scrollToTop();
    }
  }, [props.gridData]);

  useEffect(() => {
    const types = [];
    if (props.hasElectricityAccount) types.push(EssAccountsTypes.Electricity);
    if (props.hasGasAccount) types.push(EssAccountsTypes.Gas);
    if (props.hasRenewableAccount) types.push(EssAccountsTypes.Renewables);
    setGridTypes(types);
  }, [props.hasRenewableAccount, props.hasElectricityAccount, props.hasGasAccount]);

  useEffect(() => {
    setSelectedType(props.initialType);
  }, [props.initialType]);

  useEffect(() => {
    const invoiceOptions = [
      { value: 'invoice', display: 'Invoice' },
      { value: 'summary', display: 'Summary' },
    ];

    const statementOptions = [
      { value: 'statements', display: 'Statements' },
      { value: 'backingdata', display: 'Backing Data' },
    ];

    setDownloadOptions(selectedType === EssAccountsTypes.Renewables ? statementOptions : invoiceOptions);
  }, [selectedType]);

  const clearSelection = () => {
    setSelectedRow(null);
    setExpandRow(false);
    setSelectAll(false);
    setCheckedItems([]);
  };

  const handleClearAllClick = (): void => {
    props.handleClearAllClick();
  };

  const handleTypeChange = (type: string) => {
    setSelectedType(type);
    clearSelection();
    props.handleTypeChange(type, { field: 'billing-period-end-date', order: 'DESC' });
  };

  const handleMessageSelect = (event: any, row: number | null) => {
    const wrapper = event.target.closest('td')?.classList?.contains?.('select-item'); //!!
    if (wrapper) {
      return;
    }
    if (row !== undefined) {
      setSelectedRow(row);
      setExpandRow(false);
    }
  };

  const downloadStatement = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => {
    event.preventDefault();

    const essAdhocInvoiceIds: Array<number> = [];
    const item = props.gridData[index] as Api.IRenewableStatement;
    if (item.essAdhocInvoiceId) {
      essAdhocInvoiceIds.push(item.essAdhocInvoiceId);
      props.handleDownloadReport(essAdhocInvoiceIds);
    } else {
      console.log('Ess Adhoc Invoice ID is not valid');
    }
  };

  const downloadBackingData = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => {
    event.preventDefault();

    let essAdhocInvoiceIds: Array<number> = [];
    const item = props.gridData[index] as Api.IRenewableStatement;
    if (item.essAdhocInvoiceId) {
      essAdhocInvoiceIds.push(item.essAdhocInvoiceId);
      props.handleDownloadBackingData(essAdhocInvoiceIds);
    } else {
      console.log('Ess Adhoc Invoice ID is not valid');
    }
  };

  const toggleRow = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    if (!expandRow) {
      TagManager.pushData({
        event: 'View Invoice Account info',
      });
    }
    setExpandRow(!expandRow);
  };

  const handleItemChange = (index: number) => {
    setSelectedRow(null);

    const items = checkedItems.slice(0, checkedItems.length);

    const foundIndex = items.findIndex((checkedIndex: number) => checkedIndex === index);

    if (foundIndex > -1) {
      items.splice(foundIndex, 1);
    } else {
      items.push(index);
    }

    if (items.length === props.gridData.length) {
      handleSelectAll();
    } else if (isAllSelected) {
      setSelectAll(false);
    }

    setCheckedItems(items);
  };

  const handleSelectAll = () => {
    setSelectedRow(null);

    const select = !isAllSelected;
    setSelectAll(select);
    if (select) {
      const items: Array<number> = props.gridData.map((item, index) => index);
      setCheckedItems(items);
    } else {
      // unselect
      setCheckedItems([]);
    }
  };

  const handlePagingChange = () => {
    if (isAllSelected) {
      setSelectAll(!isAllSelected);
    }
    props.handlePagingChange();
  };

  const isChecked = (index: any) => {
    const foundIndex = checkedItems.findIndex((checkedIndex: number) => checkedIndex === index);
    return foundIndex > -1;
  };

  const onClickSort = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const typeId = e.currentTarget.dataset.value || '';
    let details = sortDetails;
    if (details.field === typeId) {
      if (details.order === 'ASC') details.order = 'DESC';
      else details.order = 'ASC';
    } else {
      details.field = typeId;
      details.order = 'ASC';
    }
    props.handleSortChange(selectedType, details);
  };

  const getEssDocumentIds = () => {
    let essDocumentIds: Array<number> = [];
    checkedItems.map((index: number) => {
      const item = props.gridData[index] as Api.IInvoiceDetail;
      if (item.essDocumentID) {
        essDocumentIds.push(item.essDocumentID);
      }
      return item;
    });
    return essDocumentIds;
  };

  const getEssAdhocInvoiceIds = () => {
    let essAdhocInvoiceIds: Array<number> = [];
    checkedItems.map((index: number) => {
      const item = props.gridData[index] as Api.IRenewableStatement;
      if (item.essAdhocInvoiceId) {
        essAdhocInvoiceIds.push(item.essAdhocInvoiceId);
      }
      return item;
    });
    return essAdhocInvoiceIds;
  };

  const getEssInvoiceIds = () => {
    let essInvoiceIds: Array<number> = [];
    checkedItems.map((index: number) => {
      const item = props.gridData[index] as Api.IInvoiceDetail;
      if (item.essInvoiceID) {
        essInvoiceIds.push(item.essInvoiceID);
      }
      return item;
    });
    return essInvoiceIds;
  };

  const handleDownload = (downloadType: string) => {
    if (downloadType === 'invoice') {
      const essDocumentIds = getEssDocumentIds();
      if (essDocumentIds.length === 0) {
        console.log('No Document IDs are valid');
        return;
      }
      props.handleDownloadReport(essDocumentIds);
    } else if (downloadType === 'summary') {
      // Electricity / Gas
      const essInvoiceIds = getEssInvoiceIds();
      if (essInvoiceIds.length === 0) {
        console.log('No Invoice IDs are valid');
        return;
      }
      props.handleDownloadSummary(essInvoiceIds);
    } else if (downloadType === 'statements') {
      // Renewables
      const essAdhocInvoiceIds = getEssAdhocInvoiceIds();
      if (essAdhocInvoiceIds.length === 0) {
        console.log('No Ess Adhoc Invoice IDs are valid');
        return;
      }
      props.handleDownloadReport(essAdhocInvoiceIds);
    } else if (downloadType === 'backingdata') {
      // Renewables
      const essAdhocInvoiceIds = getEssAdhocInvoiceIds();
      if (essAdhocInvoiceIds.length === 0) {
        console.log('No Ess Adhoc Invoice IDs are valid');
        return;
      }
      props.handleDownloadBackingData(essAdhocInvoiceIds);
    }
  };

  const getHeaderItems = () => {
    if (process.env.REACT_APP_BRAND === BRANDS.DRAX) {
      return draxInvoicesHeaders;
    }
    if (props.hasRenewableAccount && selectedType === EssAccountsTypes.Renewables) {
      return statementHeaders;
    }
    return invoicesHeaders;
  };

  const getActions = () => {
    if (process.env.REACT_APP_BRAND === BRANDS.DRAX) {
      return getInvoiceActions;
    }
    if (props.hasRenewableAccount && selectedType === EssAccountsTypes.Renewables) {
      return getStatementActions;
    }
    return getInvoiceActions;
  };

  const renderIcon = () => <td className='icon'>{getFuelIcon(selectedType)}</td>;

  const renderPeriod = (value: number) => {
    if (isDraxBrand) {
      return <td className='d-none d-md-table-cell'>{value}</td>;
    }
    if (isOpusBrand) {
      return <td className='d-none d-md-table-cell'>{moment(value).format('MMM YY')}</td>;
    }
  };

  const renderCustomerSiteReference = (value: string, row: any) => {
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (process.env.REACT_APP_BRAND === BRANDS.DRAX) {
        props.handleSiteBlur(row.siteID, e.target.value);
      } else {
        props.handleSiteBlur(row.portalSiteId, e.target.value);
      }
    };

    return (
      <td className='d-none d-md-table-cell'>
        {/* TODO: Check if <SiteRefField /> can we used in this case */}
        {/* TODO: API doesn't have: essSiteID, supplyActive, customerSiteReference*/}
        {/*<SiteRefField*/}
        {/*  siteId={row.essSiteID}*/}
        {/*  readOnly={!row.supplyActive}*/}
        {/*  siteReference={row.customerSiteReference}*/}
        {/*/>*/}

        <RowInputItem
          value={value || ''}
          placeholder='Edit'
          onHandleBlur={handleBlur}
          style={{ width: '150px' }}
          readOnly={row.siteEndDate && moment(row.siteEndDate).isBefore(new Date())}
        />
      </td>
    );
  };

  const renderSiteReference = (value: string, row: any) => {
    if (isDraxBrand) {
      return <></>;
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => props.handleSiteBlur(row.siteID, e.target.value);
    return (
      <td className='d-none d-md-table-cell'>
        <RowInputItem
          value={value || ''}
          placeholder='Edit'
          onHandleBlur={handleBlur}
          style={{ width: '150px' }}
          readOnly={row.siteEndDate && moment(row.siteEndDate).isBefore(new Date())}
        />
      </td>
    );
  };

  const renderAmount = (value: string, row: any, index: number) => (
    <td className={`${selectedRow === index ? 'd-none d-md-table-cell' : ''} amount-cell`}>
      {Number(value).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
    </td>
  );
  const getInvoiceActions = (row: any, index: number) => (
    <div>
      <a href='/' onClick={toggleRow} className='d-md-inline' style={{ marginLeft: '10px' }}>
        <IcomoonReact
          size={20}
          iconSet={iconSet}
          className='icon-chevron'
          icon={expandRow && selectedRow === index ? 'chevron-up' : 'chevron-down'}
        />
      </a>
    </div>
  );

  const getStatementActions = (row: any, index: number) => (
    <div>
      <div>
        <ButtonPrimary
          title='Statement'
          onClick={(event: any) => {
            downloadStatement(event, index);
          }}
          className='col m-0 d-block'
        />
      </div>
      <div>
        <ButtonTerinary
          title='Backing data'
          onClick={(event: any) => {
            downloadBackingData(event, index);
          }}
          className='col m-0 d-block'
        />
      </div>
    </div>
  );

  const getExpandableRow = (row: any, rowIndex: number) => (
    <ExpandableRow className={`sub-row ${selectedRow === rowIndex ? 'selected' : ''} ${expandRow ? 'open' : ''}`}>
      <div style={{ padding: '0 0', borderTop: '1px solid black' }}>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr className='expandable-content'>
              {isOpusBrand && (
                <td>
                  <span>Customer ID</span>
                  <p className='primary'>{row.customerID}</p>
                </td>
              )}
              <StyledTd colWidth={'20%'}>
                <span>{isDraxBrand ? 'Customer account number' : 'Account ID'}</span>
                <p className='primary'>{isDraxBrand ? row.customerID : row.accountID}</p>
              </StyledTd>
              <StyledTd colWidth={'20%'}>
                <span>Customer site reference</span>
                <p className='primary'>{row.customerSiteReference}</p>
              </StyledTd>
              <StyledTd colWidth={'40%'}>
                <span>Site details</span>
                <p className='primary'>{row.siteAddress}</p>
              </StyledTd>
              {selectedType === EssAccountsTypes.Electricity && (
                <StyledTd colWidth={'20%'}>
                  <span>MPAN</span>
                  <p className='primary'>{row.mpanElec}</p>
                </StyledTd>
              )}
              {selectedType === EssAccountsTypes.Gas && (
                <StyledTd colWidth={'20%'}>
                  <span>MPRN</span>
                  <p className='primary'>{row.mprnGas}</p>
                </StyledTd>
              )}
              <StyledTd colWidth={'25%'}>
                <span>Due date:</span>
                <p className='primary'>
                  {row.paymentDue !== null || undefined ? moment(row.paymentDue).format(DATE_FORMAT) : 'N/A'}
                </p>
              </StyledTd>
            </tr>
          </tbody>
        </table>
      </div>
    </ExpandableRow>
  );

  const title = props.hasRenewableAccount ? 'Invoices & Statements' : 'Invoices';
  const headers = getHeaderItems();
  const actions = getActions();

  const actionStyles = {
    width: '150px',
    opacity: '1',
  };

  const InvoiceFilterAdditionalItems = () => {
    const additionalItemsWarning = (
      <div className='warning-text mb-1'>*You can select a maximum of 900 invoices in a single request.</div>
    );

    const additionalItems = [
      <ButtonPrimary
        key='invoiceAdditionalItem1'
        title={checkedItems.length > 1 ? `Download ${checkedItems.length} invoices` : 'Download'}
        disabled={checkedItems.length <= 0 || checkedItems.length > 900}
        className={checkedItems.length > 900 ? 'ml-4 mb-4 mt-0' : 'ml-auto mb-4 mt-0'}
        onClick={(event: any) => {
          event.preventDefault();
          const data: any = {
            'transaction-ids': getEssDocumentIds(),
          };
          setIsDownloadRequestedModalOpened(true);
          downloadInvoices(process.env.REACT_APP_API_URL, data, authContext, userDetail.essCustomerId);
        }}
      />,
    ];

    if (checkedItems.length > 900) {
      additionalItems.unshift(additionalItemsWarning);
    }

    return additionalItems;
  };

  const handleModalClose = () => {
    setIsDownloadRequestedModalOpened(false);
    clearSelection();
  };

  const handleCustomerFilterChange = (account: Common.IOption) => {
    clearSelection();
    props.handleCustomerFilterChange(account);
  };

  const handlePeriodFilterChange = (value: any) => {
    clearSelection();
    props.handlePeriodFilterChange(value);
  };

  const handleSiteFilterChange = (option: Common.IOption) => {
    clearSelection();
    props.handleSiteFilterChange(option);
  };

  const handleMpanMprnFilterChange = (option: Common.IOption) => {
    clearSelection();
    props.handleMpanMprnFilterChange(option);
  };

  return (
    <InvoicesWrapper>
      <FilterRowWrapper>
        <StyledTitle>{title}</StyledTitle>
        <div className='help-text'>
          To download invoices*, select the corresponding checkbox and click the download button.
        </div>
      </FilterRowWrapper>
      <GridFilter
        showSites={true}
        showDate={true}
        showPeriod={true}
        showMpanMprn={true}
        showClearAll={true}
        siteData={props.siteData}
        showAccount={isOpusBrand}
        showDownload={isOpusBrand}
        selectedType={selectedType}
        accountData={props.accountData}
        handleDownload={handleDownload}
        customerData={props.customerData}
        mpanMprnData={props.mpanMprnData}
        downloadOptions={downloadOptions}
        handleClearAllClick={handleClearAllClick}
        enableDownloadBtn={checkedItems.length > 0}
        handleDateFilterChange={props.handleDateFilterChange}
        handlePeriodFilterChange={handlePeriodFilterChange}
        handleSiteFilterChange={handleSiteFilterChange}
        handleAccountFilterChange={props.handleAccountFilterChange}
        handleCustomerFilterChange={handleCustomerFilterChange}
        handleMpanMprnFilterChange={handleMpanMprnFilterChange}
        renderAdditionalListItems={InvoiceFilterAdditionalItems}
      />

      {isDownloadRequestedModalOpened && (
        <InvoiceDownloadModal
          show={isDownloadRequestedModalOpened}
          onClose={handleModalClose}
          invoiceCount={checkedItems.length}
        />
      )}

      <GridTypeSelector initialType={selectedType} types={gridTypes} handleTypeChange={handleTypeChange} />

      {!props.isLoading && props.gridData.length === 0 && <NoResults />}

      <InvoicesGridWrapper>
        {props.gridData.length > 0 && (
          <>
            <InfiniteScroll
              dataLength={props.gridData.length}
              next={handlePagingChange}
              hasMore={props.hasMoreData}
              loader={''}
              scrollableTarget='scrollableDiv'
              scrollThreshold={0.9}
            >
              <div
                id='scrollableDiv'
                ref={scrollableTable}
                className='table-responsive table-fixed fixed-column columns-1'
              >
                <Table
                  isSelectable={true}
                  headers={headers}
                  actions={actions}
                  rows={props.gridData}
                  isChecked={isChecked}
                  sortDetails={sortDetails}
                  onClickSort={onClickSort}
                  isRowExpanded={expandRow}
                  actionStyles={actionStyles}
                  isAllSelected={isAllSelected}
                  selectedRowIndex={selectedRow}
                  expandableRow={getExpandableRow}
                  handleSelectAll={handleSelectAll}
                  handleCheckRow={handleItemChange}
                  handleSelectRow={handleMessageSelect}
                  className='list table plain text-left'
                  rendericon={renderIcon}
                  rendermonth={renderPeriod}
                  renderamount={renderAmount}
                  rendergrossAmount={renderAmount}
                  renderSiteReference={renderSiteReference}
                  rendercustomerSiteReference={renderCustomerSiteReference}
                />
              </div>
            </InfiniteScroll>
          </>
        )}

        {props.isLoading && <Loading overlay nowait />}
      </InvoicesGridWrapper>
    </InvoicesWrapper>
  );
};

InvoicesComponent.defaultProps = defaultProps;

export default InvoicesComponent;
