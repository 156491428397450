export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_FORMAT_SHORT = 'DD/MM/YY';
export const DATE_FORMAT_MONTH_YEAR = 'MMM YY';
export const REVERSED_DATE_FORMAT_HYPHEN = 'YYYY-MM-DD';
export const TIME_FORMAT = 'hh:mm';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const DATE_TIME_FORMAT_SECONDS = 'DD/MM/YYYY HH:mm:ss';
export const ISO_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

export const TPI_CUSTOMERS = 'cus';
export const TPI_ACCESSID = 'aId';

export const METER_READS_GRID_CACHE_KEY_BASE = 'METER-READS-GRID';
