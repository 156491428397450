import { useEffect, useState } from 'react';
import { useAuthState } from '../../../providers/authProvider';

import PortalFilter from '../../../common/components/PortalFilter/PortalFilter';
import { getHHMetersTwoYearsOld } from '../../../common/api/meterReadsApi';
import { HHDataRequestModal } from '../../mymessages/modalTemplates/HHDataRequestModal';
import { AccountSelectionWrapper } from './styles/AccountSelection.styles';
import { getHHSagaProcess } from '../../../common/api/dataApi';
import { HHDataRequestNoHHModal } from '../../mymessages/modalTemplates/HHDataRequestNoHHModal';

type AccountSelectionProps = {
  accountData: Array<Common.IOption>;
  handleAccountSelected: Function;
  handleAccountMpans: Function;
  handleAccountClear: Function;
  handleLoadingData: Function;
  resetData: boolean;
};

const defaultProps = {
  accountData: [],
};

export const AccountSelection = (props: AccountSelectionProps) => {
  const authContext = useAuthState();
  const [accountSelectedIndex, setAccountSelectedIndex] = useState<number | undefined>(undefined);
  const [accountRefSelected, setAccountRefSelected] = useState<Common.IOption>({ value: '', display: '' });
  const [showHHDataRequestModal, setShowHHDataRequestModal] = useState<boolean>(false);
  const [showHHDataRequestNoHHModal, setShowHHDataRequestNoHHModal] = useState<boolean>(false);

  useEffect(() => {
    setAccountRefSelected({ value: '', display: '' });
    setAccountSelectedIndex(-1);
  }, [props.resetData]);

  const handleAccountSelection = (option: any) => {
    let accountRef = { value: '', display: '' };

    if (option) {
      const index = props.accountData.findIndex(i => i.display === option.label);
      setAccountSelectedIndex(index);

      accountRef = { value: option.value, display: option.label };
      meterHHChecker(option.value);
    } else {
      accountRef = { value: '', display: '' };
      props.handleAccountClear();
    }
    setAccountRefSelected(accountRef);
    props.handleAccountSelected(accountRef);
  };

  const meterHHChecker = async (accountId: string) => {
    props.handleLoadingData(true);

    const meters = await getHHMetersTwoYearsOld(process.env.REACT_APP_API_URL, authContext, accountId);

    if (meters.length === 0) {
      setShowHHDataRequestNoHHModal(true);
      setAccountRefSelected({ value: '', display: '' });
      setAccountSelectedIndex(-1);
    } else {
      const accountArray = accountId.split(' ');
      const hhSagaChecker: Common.IResult<Array<Api.IHHSagaProcessingData>> = await getHHSagaProcess(
        process.env.REACT_APP_API_URL,
        accountArray,
        authContext
      );

      if (hhSagaChecker.data[0].processing == false) {
        createMpanArray(meters);
      } else {
        setShowHHDataRequestModal(true);
        setAccountRefSelected({ value: '', display: '' });
        setAccountSelectedIndex(-1);
      }
    }
    props.handleLoadingData(false);
  };

  const createMpanArray = (meters: any[]) => {
    let selectedAccountMpans: number[] = [];
    let selectedAccountMpansNew: Common.IOption[] = [];

    meters.map(meter => selectedAccountMpans.push(meter['mpan-core']));
    let selectedAccountMpansTemp: number[] = removeDuplicates(selectedAccountMpans);

    selectedAccountMpansTemp.map(meter =>
      selectedAccountMpansNew.push({ value: meter.toString(), display: meter.toString() })
    );
    props.handleAccountMpans(selectedAccountMpansNew);
  };

  function removeDuplicates(arr: any) {
    let unique: any[] = [];
    arr.forEach((element: any) => {
      if (!unique.includes(element)) {
        unique.push(element);
      }
    });
    return unique;
  }

  return (
    <>
      <AccountSelectionWrapper {...props}>
        <div className='AccountSelectionOverview pb-5'>
          <div className='mt-0 modal-title'>Select account</div>
          <div className='container'>
            <div className='left'>
              <PortalFilter
                className={'dropdown'}
                selectedIndex={accountSelectedIndex}
                options={props.accountData}
                placeholder={'Account ref'}
                onChange={handleAccountSelection}
                isClearable={true}
              />
            </div>
            <div className='right text-center'>
              {accountRefSelected.value == '' ? '' : `${accountRefSelected.display}`}
            </div>
          </div>
        </div>
        <div className='AccountSelectionOverviewMobile'>
          <div className='mt-0 modal-title'>Select account</div>
          <PortalFilter
            className={'dropdown'}
            selectedIndex={accountSelectedIndex}
            options={props.accountData}
            placeholder={'Account ref'}
            onChange={handleAccountSelection}
            isClearable={true}
          />
          <div className='right text-center pt-2'>
            {accountRefSelected.value == '' ? '' : `${accountRefSelected.display}`}
          </div>
        </div>
      </AccountSelectionWrapper>

      <HHDataRequestModal
        show={showHHDataRequestModal}
        onHide={() => {
          setShowHHDataRequestModal(false);
        }}
      />

      <HHDataRequestNoHHModal
        show={showHHDataRequestNoHHModal}
        onHide={() => {
          setShowHHDataRequestNoHHModal(false);
        }}
      />
    </>
  );
};

AccountSelection.defaultProps = defaultProps;
