import Modal from 'react-bootstrap/Modal';
import { ButtonPrimary } from '../../../common/components';
import { useEffect } from 'react';
import { TagManager } from '../../utils/analytics/TagManager';

type HHDataRequestFailureModalProps = {
  onHide: () => void;
  show: boolean;
};

const defaultProps = {
  show: true,
};

export const HHDataRequestFailureModal = (props: HHDataRequestFailureModalProps) => {
  const { show } = props;

  useEffect(() => {
    if (show) {
      TagManager.pushData({
        event: 'HH data request failure',
      });
    }
  }, [show]);

  const handleClose = (event: any) => {
    event.preventDefault();
    props.onHide();
  };

  return (
    <>
      <Modal animation={false} className={'custom-modal'} show={show} backdrop='static' centered>
        <Modal.Header style={{ justifyContent: 'center', textAlign: 'center' }}>
          <Modal.Title>HH data request failure</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ justifyContent: 'center', textAlign: 'center' }}>
          <p>Unfortunately we can't process your request at this time.</p>
          <p>Please try again later.</p>
        </Modal.Body>
        <Modal.Footer className='p-0'>
          <ButtonPrimary onClick={handleClose} title='OK' className='mt-3 mr-0' />
        </Modal.Footer>
      </Modal>
    </>
  );
};

HHDataRequestFailureModal.defaultProps = defaultProps;
