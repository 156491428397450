import Modal from 'react-bootstrap/Modal';
import { ButtonPrimary } from '../../../common/components';
import { useEffect } from 'react';
import { TagManager } from '../../utils/analytics/TagManager';

type HHDataRequestSuccessModalProps = {
  onHide: () => void;
  show: boolean;
};

const defaultProps = {
  show: false,
};

export const HHDataRequestSuccessModal = (props: HHDataRequestSuccessModalProps) => {
  const { show } = props;

  useEffect(() => {
    if (show) {
      TagManager.pushData({
        event: 'HH data request success',
      });
    }
  }, [show]);

  const handleClose = (event: any) => {
    event.preventDefault();
    props.onHide();
  };

  return (
    <>
      <Modal animation={false} className={'custom-modal'} show={show} backdrop='static' centered>
        <Modal.Header style={{ justifyContent: 'center', textAlign: 'center' }}>
          <Modal.Title>HH data requested</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ justifyContent: 'center', textAlign: 'center' }}>
          <p>Thank you for your request.</p>
          <p>Depending on the size of your request, it could take us up to 24 hours to deliver the data.</p>
          <p>We'll email you when the data's available - you'll then be able to access it via 'Messages'.</p>
          <p>We'll let you know via email when it's ready.</p>
        </Modal.Body>
        <Modal.Footer className='p-0'>
          <ButtonPrimary onClick={handleClose} title='OK' className='mt-3 mr-0' />
        </Modal.Footer>
      </Modal>
    </>
  );
};

HHDataRequestSuccessModal.defaultProps = defaultProps;
