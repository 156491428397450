import React, { FC, Fragment } from 'react';

import { Checkbox } from '../Checkbox/Checkbox';
import { RowSelector } from '../RowSelector/RowSelector';
import { StyledTableActionsCell } from '../StyledTableActionsCell/StyledTableActionsCell';

type TableBodyProps = {
  rows: any;
  actions?: FC;
  actionStyles: any;
  expandableRow?: FC;
  [rest: string]: any;
  isSelectable: boolean;
  isRowExpanded: boolean;
  selectedRowIndex: number | null;
  headers: Array<Common.HeaderItem>;
  isChecked: (rowIndex: number) => boolean;
  handleCheckRow?: (rowIndex: number) => void;
  handleSelectRow: (action: any, rowIndex: number) => void;
};

export const TableBody = (props: TableBodyProps) => {
  const {
    rows,
    actions,
    headers,
    isChecked,
    actionStyles,
    isSelectable,
    isRowExpanded,
    expandableRow,
    handleCheckRow,
    handleSelectRow,
    selectedRowIndex,
    ...rest
  } = props;

  return (
    <tbody>
      {rows.map((row: any, rowIndex: number) => (
        <Fragment key={`table-row-fragment-${rowIndex}`}>
          <RowSelector
            key={`row-${rowIndex}`}
            className={`item ${selectedRowIndex === rowIndex ? 'selected' : ''} ${isRowExpanded ? 'open' : ''}`}
            onClick={(event: any) => {
              if (selectedRowIndex !== rowIndex) {
                handleSelectRow(event, rowIndex);
              }
            }}
          >
            {isSelectable && (
              <td className='select-item'>
                <Checkbox
                  checked={isChecked(rowIndex)}
                  id={`invoice-${row.accountID}-${rowIndex}`} // change invoice to name of table
                  onChange={() => handleCheckRow && handleCheckRow(rowIndex)}
                />
              </td>
            )}
            {headers.map((header: any, headerIndex: number) => {
              const key = `${header.dataValue}-${rowIndex}-${headerIndex}`;
              const cellVal = row[header.dataValue];
              const renderMethod = rest[`render${header.dataValue}`];
              if (renderMethod) return <Fragment key={key}>{renderMethod(cellVal, row, rowIndex)}</Fragment>;
              return (
                <td key={key} className={header.className}>
                  {cellVal}
                </td>
              );
            })}
            {actions && (
              <StyledTableActionsCell style={actionStyles} selected={selectedRowIndex === rowIndex}>
                {actions(row, rowIndex)}
              </StyledTableActionsCell>
            )}
          </RowSelector>
          {expandableRow && isRowExpanded && selectedRowIndex === rowIndex && expandableRow(row, rowIndex)}
        </Fragment>
      ))}
    </tbody>
  );
};
