import styled from 'styled-components';
import * as variables from '../../styles/variables';
import * as mixins from '../../styles/mixins';
import * as colors from '../../styles/colors';

export const SideMenuFilterWrapper = styled.div`
  display: none;
  flex: 1;
  height: calc(100vh - 4em);
  left: -${variables.menuWidth};
  margin: 0;
  padding-right: 1em;
  position: absolute;
  top: 4em;
  width: 100%;

  &.open {
    display: flex;
    flex-direction: column;
    left: 0;
    z-index: ${variables.zIndexHigh};

    ~ .main-wrapper {
      display: none;
    }
  }

  .innerWrapper {
    background-color: ${({ theme }) => theme.bg.secondary};
    border-top-right-radius: 1em;
    padding: ${variables.spacingSmall} ${variables.spacingMedium};
    position: relative;
    height: 100%;

    a {
      display: inline-block;
    }
  }

  .close-menu-icon {
    ${mixins.borderNone};
    background-color: transparent;
    position: absolute;
    top: 0.5em;
    right: 0.5em;
  }

  /* mobile table layout */
  .filters {
    .filter {
      left: -30px;
    }

    th {
      display: block;
      font-weight: 400;
      padding: ${variables.spacingSmall} 0;
    }
  }

  .mobile-grid-filter > li {
    padding: ${variables.spacingSmall} 0 ${variables.spacingSmall} 0;
  }

  .mobile-grid-filter {
    //hate to use important but there is an in-line style for this class in the date picker component that I had to override
    .rmdp-container {
      display: block !important;
    }

    .mobile-grid-date-filter {
      .rmdp-action-buttons {
        display: none;
      }
    }

    input {
      border-radius: ${variables.inputRadius};
    }

    .grid-date-filter-input-class {
      position: relative;
      width: 100%;
      margin: 0 auto;
      cursor: pointer;
      outline: none;
      -webkit-appearance: none;
      background-position-x: 100%;
      padding: 10px 15px;
      border: 1px solid ${colors.darkGrey};
      border-radius: ${props => props.theme.filterDropdown.borderRadius};
    }

    .grid-date-filter-input-class::placeholder {
      color: ${colors.oxfordBlue};
    }
  }
`;
